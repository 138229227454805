<template>
    <div class="container">
        <div class="Project p-3">
            <h1 class="ProjectTitle">Wijn</h1>
            <p class="ProjectDescription">
                Capstone project from SAIT where we created an application for the Chestermere Wine Club to help set up events, RVSP to events with a wine being brought. 
                People could then rate the wines and store the wine ratings in their account, and the rating would automatically be updated and scored based on people at the event. 
                The average rating of the wine would go into a rankings board for the whole club to see<br/>
                Tech Used:
            </p>
            <font-awesome-icon :icon="['fab', 'java']" class="logo"/>
            <img src="@/assets/cordova_32.png">
            <img src="@/assets/Payara.png">
            <img src="@/assets/mysql.svg">
        </div>
    </div>
</template>


<style scoped lang="scss">
    .Project{
        border: 5px solid #b7ced2;
        border-radius: 4px;
        box-sizing: border-box;
        margin-top: 6px;
        margin-bottom: 16px;
        resize: vertical;
    }

  a {
  padding: 12px;
  border: 1px solid #dcdde2;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
  text-decoration: none;
  color:#002f43;
  
}



a:hover {
  background-color: #002f43;
  color:white;

}
.logo{
    height:2em;
}
img{
    height: 2em;
}

</style>