<template>
  <div class="home">
      <h1>Currently In Development:</h1>
        <InDevelopment/>
    <h1>Past Projects</h1>
        <PastProjects/>
  </div>

</template>

<script>
// @ is an alias to /src
import InDevelopment from "@/components/InDevelopment.vue";
import PastProjects from "@/components/PastProjects.vue";
 export default {
    components: {
        InDevelopment,
        PastProjects
    }
  };

</script>
