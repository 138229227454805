<template>
    <div class="container">
        <div class="Project p-3">
            <div class="row">
                <div class="col">
                    
                 <h1 class="ProjectTitle">Fantasy Title Generator</h1>
                <p class="ProjectDescription">
                    A project to create a randomly strung together title for fantasy novels. <br/>
                    
                    <a class="Visit text-right" target="_blank" href="https://www.fantasytitlemaker.com">Visit the Site</a>
                    <br/>
                    Tech Used:
                    </p>
                    <font-awesome-icon :icon="['fab', 'vuejs']" class="col"/>
                    <font-awesome-icon :icon="['fab', 'js']"  class="col"/>
                </div>
                <div class="col-auto">
                    <img src="@/assets/BookGenerator.png">
                </div>
            </div>
        </div>


        <br>
         <div class="Project p-3">
            <h1 class="ProjectTitle">Yarny</h1>
            <p class="ProjectDescription">
                A project made for IOS and Android to store patterns, stashes, and works in progress for knitting and crochet<br/>
                Tech Used:
                </p>
                <font-awesome-icon :icon="['fab', 'vuejs']" class="col"/>
                <font-awesome-icon :icon="['fab', 'js']"  class="col"/>
        </div>
        <br>
         <div class="Project p-3">
            <h1 class="ProjectTitle">Family Cook Book</h1>
            <p class="ProjectDescription">
                A project to share recipes with family and friends in a clean and simple way. Created to help families keep recipes together<br/>
                Tech Used:
                </p>
                <font-awesome-icon :icon="['fab', 'vuejs']" class="col"/>
                <font-awesome-icon :icon="['fab', 'js']"  class="col"/>
        </div>
    </div>    
</template>

<script>

</script>

<style scoped lang="scss">
    .Project{
        border: 5px solid #b7ced2;
        border-radius: 4px;
        box-sizing: border-box;
        margin-top: 6px;
        margin-bottom: 16px;
        resize: vertical;
    }

  .Visit {
  padding: 12px;
  border: 1px solid #dcdde2;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  display:inline-block;
  text-decoration: none;
  color:#002f43; 
}
img{
    height:300px;
}

a:hover {
  background-color: #002f43;
  color:white;

}

</style>
